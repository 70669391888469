import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { Link } from "gatsby"
import Menu from "./menu"

import egResume from "../assets/pdfs/elena-gonzalez-resume.pdf"

const Navigation = () => (
  <nav>
    <div className="navigation-large d-none d-md-flex justify-content-between fixed-top">
      <div className="d-flex justify-content-between name">
        <Link to="/">
          <StaticImage
            src="../assets/images/icon-green.png"
            alt="Elena Guacimara Gonzalez logo"
            placeholder="blurred"
            layout="fixed"
            width={35}
            height={35}
            quality={80}
          />
        </Link>
      </div>
      <div className="d-flex justify-content-between section-nav">
        <a href={egResume}>Resume/CV</a>
        <Link to="/publications/all">Publications</Link>
        <Link to="/about">About</Link>
        <Link to="/contact">Contact</Link>
      </div>
    </div>
    <div className="navigation-small d-flex justify-content-between d-md-none fixed-top">
      <div className="d-flex justify-content-between name">
        <StaticImage
          src="../assets/images/icon-green.png"
          alt="Elena Guacimara Gonzalez logo"
          placeholder="blurred"
          layout="fixed"
          width={35}
          height={35}
          quality={80}
        />
      </div>
      <div>
        <Menu />
      </div>
    </div>
  </nav>
)

export default Navigation
