import React from "react"

const Footer = () => (
  <footer className="d-none d-md-flex">
    <div className="footer justify-content-between">
      <div className="d-flex justify-content-between">
        <a
          href="https://www.linkedin.com/in/elenaguacimara/?originalSubdomain=es"
          className="nav-link"
        >
          LinkedIn
        </a>
        <a
          href="https://scholar.google.es/citations?user=1wjClfcAAAAJ&hl=us"
          className="nav-link"
        >
          Google Scholar
        </a>
        <a
          href="https://publons.com/researcher/3881097/elena-g-gonzalez/"
          className="nav-link"
        >
          Researcher ID
        </a>
        <a href="https://orcid.org/0000-0002-4614-3889" className="nav-link">
          ORCID
        </a>
      </div>
    </div>
  </footer>
)

export default Footer
